import type { IncomingMessage, ServerResponse } from 'http'
import get from 'lodash/get'
import { useRouter } from 'next/router'
import objectScan from 'object-scan'
import qs from 'query-string'
import { ParsedUrlQuery } from 'querystring'

import { config } from './config'

export const scanGet = <T = any>(obj: any, glob: string[]): T | undefined => {
  const path = objectScan(glob)(obj)
  return get(obj, path[0])
}

export interface CartDefaults {
  tableArea?: string
  tableNumber?: string
  googlePlaceId?: string
}

export const useCartDefaults = (): CartDefaults => {
  const { query } = useRouter()
  const defaults: CartDefaults = {}

  if (query.number || query.area) {
    defaults.tableArea = query.area ? String(query.area) : ''
    defaults.tableNumber = query.number
      ? String(query.number).toUpperCase()
      : ''
  }

  if (query.place) {
    defaults.googlePlaceId = query.place ? String(query.place) : ''
  }

  return defaults
}

export const getCleanPath = (url: string, query?: ParsedUrlQuery) =>
  qs.stringifyUrl({
    url,
    query: {
      ...query,
      orderToken: undefined,
      status: undefined,
      eonxCheckInId: undefined,
    },
  })

export const formatPoints = (points: number | undefined) => {
  if (!points) return '0'

  if (Number.isInteger(points)) {
    return points.toLocaleString()
  } else {
    const roundedPoints = Math.floor(points * 100) / 100
    return roundedPoints.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
}

export const getCookieValue = (cookies: string, cookieName: string) => {
  const cookie = cookies
    .split(';')
    .map((v) => v.trim().split('='))
    .find((v) => v[0] === cookieName)

  if (cookie) {
    return cookie.length > 1 ? cookie[1] : ''
  }

  return undefined
}

export const appendSetCookie = (res: ServerResponse, setCookie: string) => {
  const previousSetCookie = res.getHeader('set-cookie')
  if (previousSetCookie) {
    res.setHeader(
      'set-cookie',
      Array.isArray(previousSetCookie)
        ? [...previousSetCookie, setCookie]
        : [`${previousSetCookie}`, setCookie],
    )
    return
  }

  res.setHeader('set-cookie', setCookie)
}

export const appendCookie = (req: IncomingMessage, cookie: string) => {
  const previousCookie = req.headers['cookie']
  if (previousCookie) {
    req.headers['cookie'] = `${previousCookie}; ${cookie}`
    return
  }

  req.headers['cookie'] = cookie
}

export const getGuestGatewayUrl = (
  env: string,
  region: string,
  domain: string,
) => {
  const subdomain = domain.startsWith('www.')
  if (subdomain) {
    domain = domain.split('www.')[1]
  }
  let guestGatewayUrl = `https://${region}-guest-gateway.${domain}/graphql`

  // Handle Domain's using the direct DNS record
  // ap1-staging.mryum.com/<venue>
  // (ap1|eu1|us1).mryum.com/<venue>
  // ap1.staging.meandu.app/<venue>
  // (ap1|eu1|us1).meandu.app/<veneue>
  if (domain.includes(region)) {
    if (env === 'staging') {
      guestGatewayUrl = domain.includes('mryum.com')
        ? `https://${region}-staging-guest-gateway.mryum.com/graphql`
        : `https://${region}-guest-gateway.staging.meandu.app/graphql`
    } else {
      guestGatewayUrl = domain.includes('mryum.com')
        ? `https://${region}-guest-gateway.mryum.com/graphql`
        : `https://${region}-guest-gateway.meandu.app/graphql`
    }
    return guestGatewayUrl
  }

  // dev.meandu.app/mryum.com doesn't have a dedicated staging domain. Need to manully return the value
  if (env === 'staging') {
    if (domain.includes('mryum.com')) {
      return 'https://ap1-staging-guest-gateway.mryum.com/graphql'
    } else if (domain.includes('dev.meandu.app')) {
      return 'https://ap1-guest-gateway.staging.meandu.app/graphql'
    }
  }

  // handles mryum.local
  if (env === 'development') {
    return 'https://mryum.local/guest-gateway/dev/graphql'
  }

  return guestGatewayUrl
}

export const ORIGIN_COOKIE =
  config.environment === 'production' ? 'mryum.origin' : 'staging-mryum.origin'
